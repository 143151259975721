@import "./common-mixin.scss";

/*============================
    Archinza colors    
============================*/
$color-FFFFFF: #ffffff;
$color-000000: #000000;
$color-F77B00: #f77b00;

body {
  font-family: "Poppins";
  background-color: #000;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #545353;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
}
.row {
  margin-left: 0;
  margin-right: 0;
}
:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px;
}

::-moz-focus-inner {
  border: 0;
}
button:focus,
textarea:focus,
input:focus {
  outline: none;
}

li {
  list-style-type: none;
}


@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: 2174.7px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1921px) {
  .my_container {
    max-width: 1631px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0;
  }
}

@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: 1631px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1919px) {
  body {
    font-size: 14px;
  }
  .my_container {
    max-width: 1304.8px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1536px) {
  body {
    font-size: 11px;
  }
}
@media (max-width: 1440px) {
  body {
    font-size: 10.5px;
  }
  .my_container {
    max-width: 1223.25px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1160.38px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 9.5px;
  }
  .my_container {
    max-width: 1087.33px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1014.27px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 834px) {
  .my_container {
    max-width: 708.46px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

// @media (max-width: 767px) {
//   body {
//     font-size: 10px;
//   }
// }
