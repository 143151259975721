@import "../../common.scss";

.header_sec1 {
  background-color: $color-000000;
  padding: 1.666em 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  z-index: 10;
  .header_logo {
    display: block;
    width: 100%;
    max-width: 16.5em;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .header_sec1 {
    padding: 2.2em 0 2.22em;
    .header_logo {
      max-width: 15.231em;
    }
  }
}
